<template>
  <v-container
    :class="ShowMainPage === 'Y' ? 'pa-0 custom-bg' : 'pa-0'"
    fluid>
      <v-container
        id="Register"
        tag="section"
        class="pa-0"
      >
      <div id="app">
        <!-- <v-main> -->
          <v-card flat color="#F8F9F9" elevation="9" class="mx-0 pa-0"> 
            <v-tabs center-active dense flat color="black" elevation="0" >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                light
                class="display-2"
                v-model="tabCompany"
                color="#F8F9F9"
                ref="tabCompany"
                @click="companyFlag = 'Y'"
                >
                นิติบุคคล
              </v-tab>
              <v-tab-item color="#F8F9F9">
                <v-card elevation="0">
                  <v-card-title align="center">
                  <span class="display-3"><strong>{{ $t('Register.applicationFormCompany') }}</strong></span>
                  </v-card-title>
                  <v-card-text>
                    <v-row row wrap>          
                      <v-col cols="12" lg="3">            
                        <v-autocomplete
                          :readonly="productSkey"
                          class="bg-editor custom-label-color"
                          dense
                          hide-details
                          outlined
                          v-model="productId"
                          :items="productList"
                          :search-input.sync="productName"
                          item-text="product_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.product') }}</span>
                          </template>              
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>          
                      </v-col> 
                    </v-row>
                    <v-row row wrap class="ma-0 pa-0">
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-combobox
                          outlined dense hide-details
                          :label="$t('Register.companyPrefix')"
                          v-model="companyPrefix"
                          :items="companyPrefixList"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('Register.companyPrefix') }}</span>
                          </template>
                        </v-combobox>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          color="black"
                          class="bg-editor custom-label-color"
                          dense hide-details 
                          v-model="companyName"
                          outlined>
                          <!-- :label="$t('Register.NameSurName')"  -->
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.companyName') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          color="black"
                          class="bg-editor custom-label-color"
                          dense hide-details 
                          v-model="companyId"
                          maxlength="13"
                          outlined>
                          <!-- :label="$t('Register.NameSurName')"  -->
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.companyId') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                    </v-row>
                    <v-row row wrap class="ma-0 pa-0">           
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">            
                        <v-autocomplete
                          class="bg-editor"
                          dense
                          hide-details
                          outlined
                          v-model="companyProvinceId"
                          :items="provinceList"
                          :search-input.sync="companyProvinceName"
                          item-text="name_th"
                          item-value="id"
                          @change="resetDistrictSubdistrictCompany"
                        >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.provinces') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-caption">*ที่อยู่ที่สามารถติดต่อได้</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <!-- <addressinput-district v-model="district" />  -->
                        <v-autocomplete
                          class="bg-editor"
                          dense
                          hide-details
                          outlined
                          v-model="companyDistrictId"
                          :items="filterDistrictCompany"
                          :search-input.sync="companyDistrictName"
                          item-text="name_th"
                          item-value="id"
                          @change="resetSubdistrictCompany"
                        >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.amphur') }}</span>
                          </template>              
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>         
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          class="bg-editor"
                          dense hide-details 
                          v-model="companyContactFirstName"
                          outlined
                          >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.companyContactFirstName') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          class="bg-editor"
                          dense hide-details 
                          v-model="companyContactLastName"
                          outlined
                          >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.companyContactLastName') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          dense hide-details 
                          class="bg-editor"
                          v-model="companyContactPhone"
                          min="10"
                          maxlength="10"
                          outlined>
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.companyContactPhone') }}</span>
                          </template>
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          dense hide-details 
                          class="bg-editor"
                          v-model="companyContactEmail"
                          outlined>
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.companyContactEmail') }}</span>
                          </template>
                        </v-text-field>
                        <span class="red--text text-body"> </span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">       
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="guaranteeSkey"
                          :items="guaranteeList"
                          :search-input.sync="guaranteeName"
                          item-text="guarantee_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.Guarantee') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          dense hide-details 
                          class="bg-editor"
                          v-model="deedNo"
                          outlined>
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.DeedNo') }}</span>
                          </template>
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                    </v-row>
                    <v-row row wrap  class="ma-0 pa-0">
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="guaranteeProvinceId"
                          :items="provinceList"
                          :search-input.sync="guaranteeProvinceName"
                          item-text="name_th"
                          item-value="id"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.GuaranteeProvince') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="companyConvenientTime"
                          :items="convenientTimeList"
                          :search-input.sync="companyConvenientTimeName"
                          item-text="convenient_time_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.ConvenientTime') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="companyReceiveChannel"
                          :items="receiveChannelList"
                          :search-input.sync="companyReceiveChannelName"
                          item-text="receive_channel_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.ReceiveChannel') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <vuetify-money
                          outlined dense hide-details
                          v-model="companyCreditLimit"
                          v-bind:options="companyOptionsCreditLimit"
                          v-bind:label="$t('RegisterMaint.CreditLimit')"
                          v-bind:valueWhenIsEmpty="null"
                          v-bind:properties="companyProperties"
                        >	
                          <template v-slot:label>	
                            <span class="input__label text-title">{{ $t('RegisterMaint.CreditLimit') }}</span>	
                          </template> 	
                        </vuetify-money>
                        <span class="red--text text-body">*</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab
                light
                class="display-2"
                v-model="tabPerson"
                flat 
                color="#F8F9F9"
                ref="tabPerson"
                @click="companyFlag = 'N'"
                >
                บุคคลธรรมดา
              </v-tab>
              <v-tab-item color="#F8F9F9">
                <v-card elevation="0">
                  <v-card-title align="center">
                  <span class="display-3"><strong>{{ $t('Register.applicationFormPerson') }}</strong></span>
                  </v-card-title>
                  <v-card-text >
                    <v-row row wrap>       
                      <v-col cols="12" lg="3">            
                        <v-autocomplete
                          :readonly="productSkey"
                          class="bg-editor custom-label-color"
                          dense
                          hide-details
                          outlined
                          v-model="productId"
                          :items="productList"
                          :search-input.sync="productName"
                          item-text="product_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.product') }}</span>
                          </template>              
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col> 
                    </v-row>
                    <v-row row wrap class="ma-0 pa-0">
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-combobox
                          outlined dense hide-details
                          :label="$t('Register.prefix')"
                          v-model="prefix"
                          :items="prefixList"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('Register.prefix') }}</span>
                          </template>
                        </v-combobox>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          color="black"
                          class="bg-editor custom-label-color"
                          dense hide-details 
                          v-model="firstName"
                          outlined>
                          <!-- :label="$t('Register.NameSurName')"  -->
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.firstName') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          color="black"
                          class="bg-editor custom-label-color"
                          dense hide-details 
                          v-model="lastName"
                          outlined>
                          <!-- :label="$t('Register.NameSurName')"  -->
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.lastName') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          color="black"
                          class="bg-editor custom-label-color"
                          dense hide-details 
                          v-model="idCard"
                          maxlength="13"
                          outlined>
                          <!-- :label="$t('Register.NameSurName')"  -->
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.idCard') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                    </v-row>
                    <v-row row wrap  class="ma-0 pa-0">               
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">            
                        <v-autocomplete
                          class="bg-editor"
                          dense
                          hide-details
                          outlined
                          v-model="provinceId"
                          :items="provinceList"
                          :search-input.sync="provinceName"
                          item-text="name_th"
                          item-value="id"
                          @change="resetDistrictSubdistrict"
                        >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.provinces') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-caption">*ที่อยู่ที่สามารถติดต่อได้</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <!-- <addressinput-district v-model="district" />  -->
                        <v-autocomplete
                          class="bg-editor"
                          dense
                          hide-details
                          outlined
                          v-model="districtId"
                          :items="filterDistrict"
                          :search-input.sync="districtName"
                          item-text="name_th"
                          item-value="id"
                          @change="resetSubdistrict"
                        >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.amphur') }}</span>
                          </template>              
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>         
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          class="bg-editor"
                          dense hide-details 
                          v-model="telephone"
                          min="10"
                          maxlength="10"
                          outlined
                          >
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.Tel') }}</span>
                          </template>              
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          dense hide-details 
                          class="bg-editor"
                          v-model="myEmail"
                          outlined>
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.EMAIL') }}</span>
                          </template>
                        </v-text-field>
                        <span class="red--text text-body"> </span>
                      </v-col>
                    </v-row>
                    <v-row row wrap  class="ma-0 pa-0">
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">       
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="guaranteeSkey"
                          :items="guaranteeList"
                          :search-input.sync="guaranteeName"
                          item-text="guarantee_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.Guarantee') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">
                        <v-text-field
                          dense hide-details 
                          class="bg-editor"
                          v-model="deedNo"
                          outlined>
                          <template v-slot:label>
                            <span class="input__label text-body-1">{{ $t('Register.DeedNo') }}</span>
                          </template>
                        </v-text-field>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="guaranteeProvinceId"
                          :items="provinceList"
                          :search-input.sync="guaranteeProvinceName"
                          item-text="name_th"
                          item-value="id"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.GuaranteeProvince') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="convenientTime"
                          :items="convenientTimeList"
                          :search-input.sync="convenientTimeName"
                          item-text="convenient_time_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.ConvenientTime') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <v-autocomplete
                          dense
                          hide-details
                          outlined
                          v-model="receiveChannel"
                          :items="receiveChannelList"
                          :search-input.sync="receiveChannelName"
                          item-text="receive_channel_name"
                          item-value="skey"
                        >
                          <template v-slot:label>
                            <span class="input__label text-title">{{ $t('RegisterMaint.ReceiveChannel') }}</span>
                          </template>
                        </v-autocomplete>
                        <span class="red--text text-body">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="3">    
                        <vuetify-money
                          outlined dense hide-details
                          v-model="creditLimit"
                          v-bind:options="optionsCreditLimit"
                          v-bind:label="$t('RegisterMaint.CreditLimit')"
                          v-bind:valueWhenIsEmpty="null"
                          v-bind:properties="properties"
                        >	
                          <template v-slot:label>	
                            <span class="input__label text-title">{{ $t('RegisterMaint.CreditLimit') }}</span>	
                          </template> 	
                        </vuetify-money>	
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
            <v-row row wrap class="mx-2 px-2">                 
              <v-card
              outlined
              :style="'border: 1px solid pink;'"
              dense
              flat
              >
                <v-card-text dense flat class="text--primary">
                <v-btn dark
                  dense 
                  flat
                  text
                  class="text-body-1 my-0 pa-0"
                  color="pink"
                  @click="CookieLaw"
                >{{ $t('Register.messagePolicy') }}
                </v-btn>
                <v-divider class="ma-0 pa-0" inset vertical></v-divider>
                <v-checkbox class='text--primary'
                  value="Y"
                  v-model="consentFlag"
                  dense flat
                >
                  <template v-slot:label>
                    <div><span class='text--primary display-1'>{{ $t('Register.Policy') }}</span></div>
                  </template>
                </v-checkbox>
                </v-card-text>
              </v-card>
              <!-- <span class="display-1 my-2 pa-0">{{ $t('Register.Policy') }}</span> -->
            </v-row>
            <v-row row wrap class="ma-2 pa-2"> 
              <v-col align="left" cols="12" class="pt-0 pb-0 mb-0" lg="2">
                <v-btn dark
                  depressed
                  class="ma-0 pb-0"
                  color="pink"
                  @click="PrintForm()"
                >{{ $t('Register.SEND') }}
                </v-btn>

                <!-- <router-link to="/cookie-policy">{{ $t('Register.messageCookieLaw2') }}</router-link> -->
              </v-col>
              <!-- <v-col align="right" cols="12" class="pt-0 pb-0 mb-0 mt-2" lg="10" >
                <span class='text--primary display-1 font-weight-bold'>{{ $t('Register.ContactCompany') }}</span>
              </v-col> -->
            </v-row>
          </v-card>
          <v-dialog
            v-model="dialog.dialogPolicy"
            width="800px"
          >      
            <v-card>
              <v-card-text>
                <cookiepolicy></cookiepolicy>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="pink"
                  dark
                  text
                  @click="dialog.dialogPolicy = false"
                >
                  ปิด
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>            
          
          <v-dialog v-model="dialog.dialogOTP" max-width="800px" eager >
            <v-card>
              <v-card-title class="text-center my-5 py-5">
                <span class="headline">{{ $t('Register.OTP') }}</span>
              </v-card-title>
              <v-card-text class="text-center my-5 py-5"> 
                <v-container>
                  <v-row>
                    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
                      <v-card color="primary" dark>
                        <v-card-text>
                          {{progressMessage}}
                          <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                          ></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-col md="8" offset-md="2">
                      <v-otp-input
                        v-model="otp"
                        type="number"
                      ></v-otp-input>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0" lg="12">    
                      <v-overlay absolute :value="loading">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-overlay>
                    </v-col> 
                    <v-col cols="12" class="pt-0 pb-1 pl-0 my-2" lg="12">
                      Ref Code : {{refCode}} <br>
                      Remaining time : {{ timeoutOtp }} seconds
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-1 mb-0 pl-0 mx-2" md="12" lg="12">
                      <v-btn class="text-display-3 pt-0 pb-1 mb-0 pl-0 mx-0" :disabled="verifyDisabled" outlined color="primary" @click="verify" style="text-transform: capitalize">ยืนยัน OTP</v-btn>
                    </v-col>
                    <v-col>
                    <v-snackbar
                      v-model="snackbar"
                      :color="snackbarColor"
                      :timeout="2000"
                    >
                      {{ text }}
                    </v-snackbar>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text>
              <v-row justify="end">
              <v-col cols="12" md="8" lg="8">
              </v-col>
              <v-col cols="12" md="4" lg="4" class="pa-2">
                <v-btn dense flat class="text-caption pa-2 mb-2 ma-2" outlined color="primary" @click="send" style="text-transform: capitalize">ขอ OTP ใหม่</v-btn>
                <v-btn dense flat class="text-caption pa-2 mb-2 ma-2" outlined color="primary" @click="closeOTP" style="text-transform: capitalize">ปิด</v-btn>
              </v-col>
              </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>     
        <!-- </v-main> -->
      </div>
    </v-container>
  </v-container>
</template>

<script>
import 'babel-polyfill'
import Base64Binary from 'base64-arraybuffer'

export default {
  name: 'Register',
  props: ['receiveVar', 'productSkey', 'ShowMainPage'], /* Golf 2022-9-3 add 'productSkey' */
  components: {
    cookiepolicy: () => import('@/components/cookie-policy'),
    // otpVerify: () => import('@/components/mtmng/OtpVerify')
  },
  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      dialog: {
        dialogProgress: false,
        dialogAddEditWindow: false,
        dialogPathoRegisterReport: false,
        dialogReceivedDateFromTo: false,
        dialogBrowseVendor: false,
        dialogInvtReceiveFromSupplierAction: false,
        dialogPolicy: false,
        dialogPrint: false,
        dialogOTP: false,
      },
      dropDownData: [],
      productList: [],
      productId: 0,
      productName: '',
      nameSurName: '',
      telephone: '',
      myEmail: '',
      lineId: '',
      consentFlag: '',
      zipCode: '',
      provinceId: 0,
      districtId: 0,
      subDistrictId: 0,
      provinceName: '',
      districtName: '',
      subDistrictName: '',
      provinceList: require('@/data/thai_provinces.json'),
      districtList: require('@/data/thai_amphures.json'),
      subDistrictList: require('@/data/thai_tambons.json'),
      subDistrict: [],
      uploadList: [],
      numberRule: val => {
        if (val < 0) return 'Please enter a positive number'
        return true
      },
      chooseFile: null,
      data: null,
      fileList: [],
      registerSkey: 0,
      firstName: '',
      lastName: '',
      otpCode: 'VUEJ',
      idCard: '',
      loading: false,
      snackbar: false,
      snackbarColor: 'default',
      smsTo: '',
      otp: '',
      text: '',
      refCode: '',
      otpDate: null,
      incorrectCount: 0,
      timeoutOtp: 0,
      regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      // regTelephone: /^\d{10}$/
      regTelephone: /^((0)(\d{9}))$/,
      companyName: '',
      companyId: '',
      companyProvinceId: 0,
      companyDistrictId: 0,
      companySubDistrictId: 0,
      companyProvinceName: '',
      companyDistrictName: '',
      companySubDistrictName: '',
      companyContactFirstName: '',
      companyContactLastName: '',
      companyContactPhone: '',
      companyContactEmail: '',
      companyFlag: 'N',
      isDupNumber: false,
      guaranteeList: [],
      guaranteeSkey: 0,
      guaranteeName: '',
      guaranteeProvinceId: 0,
      guaranteeProvinceName: '',
      deedNo: '',
      convenientTimeList: [],
      receiveChannelList: [],
      convenientTime: '',
      convenientTimeName: '',
      receiveChannel: '',
      receiveChannelName: '',
      creditLimit: 0,
      companyConvenientTime: '',
      companyConvenientTimeName: '',
      companyreceiveChannel: '',
      companyReceiveChannelName: '',
      companyCreditLimit: 0,
      optionsCreditLimit: {
        locale: 'th-TH',
        // prefix: '฿',
        suffix: '฿',
        length: 20,
        precision: 0
      },
      properties: {
        class: 'custom-label-color custom-placeholder-color text-title',
        color: 'black'
      },
      companyOptionsCreditLimit: {
        locale: 'th-TH',
        // prefix: '฿',
        suffix: '฿',
        length: 20,
        precision: 0
      },
      companyProperties: {
        class: 'custom-label-color custom-placeholder-color text-title',
        color: 'black'
      },
      userId: '',
      prefix: '',
      companyPrefix: '',
      prefixList: ['นาย', 'นาง', 'นางสาว'],
      companyPrefixList: ['บ.', 'บจก.', 'บจ.', 'บมจ.', 'บล.', 'บลจ.', 'หจก.'],
    }
  },

  computed: {
    filterProvince () {
      let options = this.provinceList
      return options.filter(o => o.id === this.provinceId)
    },
    filterDistrict () {
      let options = this.districtList
      return options.filter(o => o.province_id === this.provinceId)
    },
    filterSubDistrict () {
      let options = this.subDistrictList
      return options.filter(o => o.amphure_id === this.districtId)
    },
    filterProvinceCompany () {
      let options = this.provinceList
      return options.filter(o => o.id === this.companyProvinceId)
    },
    filterDistrictCompany () {
      let options = this.districtList
      return options.filter(o => o.province_id === this.companyProvinceId)
    },
    filterSubDistrictCompany () {
      let options = this.subDistrictList
      return options.filter(o => o.amphure_id === this.companyDistrictId)
    },
    verifyDisabled () {
      // `this` points to the component instance
      return this.timeoutOtp <= 0 || this.incorrectCount >= 3
    }
  },
  async created () {
    await this.retrieveFrontUpload('th')
    await this.getProductList('th')
    await this.retrieveFrontGuarantee('th')
    await this.retrieveFrontConvenientTime('th')
    await this.retrieveFrontReceiveChannel('th')
    this.companyFlag = 'Y'
    this.productId = this.productSkey /* Golf 2022-9-3 */
    setInterval(this.decreseTime, 1000)
    if (!this.$route.query.u) {
    } else {
      this.userId = atob(this.$route.query.u.toString().slice(0, -1))
      if (!this.userId) {
        this.$swal({type: 'error', title: 'URL ไม่ถูกต้อง'})
      }
    }
    console.log(this.userId, 'this.userId1')
  },
  /* Golf 2022-9-3 start */
  watch: {
    productSkey () {
      this.productId = this.productSkey
    },
  },
  /* Golf 2022-9-3 end */

  methods: {
    async selectLanguage (language) {
      this.uploadList = null
      await this.getProductList(language)
      await this.retrieveFrontUpload(language)
    },
    saveOTP () {
      this.closeOTP()
      this.addRegister()
    },
    closeOTP () {
      this.dialog.dialogOTP = false
    },
    CookieLaw () {
      this.dialog.dialogPolicy = true
    },
    async retrieveFrontReceiveChannel (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/receive_channel/retrieveFrontReceiveChannel' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.receiveChannelList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveFrontConvenientTime (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/convenient_time/retrieveFrontConvenientTime' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.convenientTimeList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async retrieveFrontGuarantee (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/guarantee/retrieveFrontGuarantee' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.guaranteeList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    validateReg () {
      var msg = ''
      if (!this.validateIdcard(this.idCard)) {
        msg = msg + 'เลขที่บัตรประชาชนไม่ถูกต้อง' + '<br>'
      }
      if (!this.validatePhoneNumber(this.telephone)) {
        msg = msg + 'เบอร์โทรศัพท์ไม่ถูกต้อง' + '<br>'
      }
      if (this.myEmail) {
        if (!this.validateMail(this.myEmail)) {
          msg = msg + 'อีเมลไม่ถูกต้อง' + '<br>'
        }
      }
      if (msg) {
        this.$swal({type: 'warning', title: msg})
        return false
      } else {
        return true
      }
    },
    validateRegCompany () {
      var msg = ''
      if (!this.validateIdcard(this.companyId)) {
        msg = msg + 'เลขนิติบุคคลไม่ถูกต้อง' + '<br>'
      }
      if (!this.validatePhoneNumber(this.companyContactPhone)) {
        msg = msg + 'เบอร์โทรศัพท์ไม่ถูกต้อง' + '<br>'
      }
      if (this.companyContactEmail) {
        if (!this.validateMail(this.companyContactEmail)) {
          msg = msg + 'อีเมลไม่ถูกต้อง' + '<br>'
        }
      }
      if (msg) {
        this.$swal({type: 'warning', title: msg})
        return false
      } else {
        return true
      }
    },
    async validateId (idNumber, companyFlag) {
      await this.axios.post(process.env.VUE_APP_API + '/register/validateId' + process.env.VUE_APP_DATABASE_TYPE, {
        number: idNumber,
        companyFlag: companyFlag
      })
        .then(async response => {
          if (response.data.isSuccess) {
            if (companyFlag === 'Y') {
              this.validateCreditLimit(this.productId, this.companyCreditLimit, companyFlag)
            } else {
              this.validateCreditLimit(this.productId, this.creditLimit, companyFlag)
            }
          } else {
            if (companyFlag === 'N') {
              this.$swal({type: 'warning', title: 'เลขบัตรประชาชน ' + idNumber + ' ได้ลงทะเบียนสมัครสินเชื่อไว้แล้ว '})
            } else {
              this.$swal({type: 'warning', title: 'เลขนิติบุคคล ' + idNumber + ' ได้ลงทะเบียนสมัครสินเชื่อไว้แล้ว '})
            }
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async validateCreditLimit (productSkey, creditLimit, companyFlag) {
      await this.axios.post(process.env.VUE_APP_API + '/register/validateCreditLimit' + process.env.VUE_APP_DATABASE_TYPE, {
        productSkey: productSkey,
        creditLimit: creditLimit,
        companyFlag: companyFlag
      })
        .then(async response => {
          if (response.data.isSuccess) {
            this.dialog.dialogOTP = true
            this.otp = null
            this.send()
            // this.addRegister()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    PrintForm () {
      // this.dialog.dialogOTP = true
      if (this.companyFlag === 'N') {
        if (!this.productId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.prefix) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.firstName) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.lastName) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.idCard) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.telephone) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.provinceId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.districtId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.guaranteeSkey) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.deedNo) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.guaranteeProvinceId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.consentFlag) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messagePolicy')})
          return
        }
        if (!this.deedNo) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.guaranteeProvinceId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.consentFlag) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messagePolicy')})
          return
        }
        if (!this.convenientTime) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageConvenientTime')})
          return
        }
        if (!this.receiveChannel) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageReceiveChannel')})
          return
        }
        console.log('creditLimit')
        console.log(this.creditLimit.toString())
        if (this.creditLimit === 0) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageCreditLimit')})
          return
        }
        if (this.validateReg() === true) {
          this.validateId(this.idCard, this.companyFlag)
        }
      } else {
        if (!this.productId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyPrefix) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyName) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyProvinceId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyDistrictId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyContactFirstName) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyContactLastName) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.companyContactPhone) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.guaranteeSkey) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.deedNo) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.guaranteeProvinceId) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageMandatory')})
          return
        }
        if (!this.consentFlag) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messagePolicy')})
          return
        }
        if (!this.companyConvenientTime) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageConvenientTime')})
          return
        }
        if (!this.companyReceiveChannel) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageReceiveChannel')})
          return
        }
        console.log('companyCreditLimit')
        console.log(this.companyCreditLimit.toString())
        if (this.companyCreditLimit === 0) {
          this.$swal({type: 'warning', title: this.$t('RegisterMaint.messageCreditLimit')})
          return
        }
        if (this.validateRegCompany() === true) {
          this.validateId(this.companyId, this.companyFlag)
        }
      }
    },
    resetDistrictSubdistrict () {
      // this.$swal({type: 'error', html: this.provinceId})
      this.districtId = 0
      this.subDistrictId = 0
    },
    resetDistrictSubdistrictCompany () {
      // this.$swal({type: 'error', html: this.provinceId})
      this.companyDistrictId = 0
      this.companySubDistrictId = 0
    },
    resetSubdistrict () {
      this.subDistrictId = 0
    },
    setZipcode () {
      let options = this.subDistrictList
      let zip = options.filter(o => o.id === this.subDistrictId)
      this.zipCode = zip[0].zip_code
    },
    resetData () {
      // this.productId = null
      // this.productName = null
      this.firstName = null
      this.lastName = null
      this.telephone = null
      this.provinceId = null
      this.provinceName = null
      this.districtId = null
      this.districtName = null
      this.subDistrictId = null
      this.subDistrictName = null
      this.zipCode = null
      this.refCode = null
      this.consentFlag = null
      this.myEmail = null
      this.lineId = null
      this.idCard = null
      this.companyName = null
      this.companyId = null
      this.companyProvinceId = null
      this.companyProvinceName = null
      this.companyDistrictId = null
      this.companyDistrictName = null
      this.companyContactFirstName = null
      this.companyContactLastName = null
      this.companyContactPhone = null
      this.companyContactEmail = null
      this.guaranteeSkey = null
      this.guaranteeName = null
      this.deedNo = null
      this.guaranteeProvinceId = null
      this.guaranteeProvinceName = null
      this.convenientTime = null
      this.convenientTimeName = null
      this.companyConvenientTime = null
      this.companyConvenientTimeName = null
      this.receiveChannel = null
      this.receiveChannelName = null
      this.companyReceiveChannel = null
      this.companyReceiveChannelName = null
      this.creditLimit = 0
      this.companyCreditLimit = 0
      this.prefix = null
      this.companyPrefix = null
    },
    async  selectFile (files, itemUpload) {
      this.chooseFile = await files
      var reader = new FileReader()
      var itemClone = itemUpload
      reader.onload = (e) => {
        var binaryData = e.target.result
        itemClone.filename = 'Test.text'
        itemClone.picture_base64 = window.btoa(binaryData)
        this.uploadList.splice(this.uploadList.indexOf(itemUpload), 1, itemClone)
      }
      reader.readAsBinaryString(this.chooseFile.files[0])
    },
    deleteFile (itemUpload) {
      this.fileList = this.fileList.filter(item => item.uploadSkey !== itemUpload.skey)
    },
    // printTT () {
    //   const prtHtml = document.getElementById('print').innerHTML

    //   // Get all stylesheets HTML
    //   let stylesHtml = ''
    //   for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    //     stylesHtml += node.outerHTML
    //   }

    //   // Open the print window
    //   const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

    //   WinPrint.document.write(`<!DOCTYPE html>
    //   <html>
    //     <head>
    //       ${stylesHtml}
    //     </head>
    //     <body>
    //       ${prtHtml}
    //     </body>
    //   </html>`)

    //   WinPrint.document.close()
    //   WinPrint.focus()
    //   WinPrint.print()
    //   WinPrint.close()
    // },
    // updateSelectedAttribute (e) {
    //   let sel, i
    //   sel = document.getElementById(e.target.id)
    //   // remove 'selected' from prior user selection
    //   for (i = 0; i < sel.length; i += 1) {
    //     sel[i].removeAttribute('selected')
    //   }
    //   // and add 'selected' to current selection
    //   sel[sel.selectedIndex].setAttribute('selected', 'selected')
    // },
    async retrieveFrontUpload (languageID) {
      await this.axios.post(process.env.VUE_APP_API + '/upload/retrieveFrontUpload' + process.env.VUE_APP_DATABASE_TYPE, {
        language_id: languageID
      })
        .then(async response => {
          this.uploadList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async addRegister () {
      var prefix = ''
      var firstName = ''
      var lastName = ''
      var provinceId = 0
      var provinceName = ''
      var districtId = 0
      var districtName = ''
      var email = ''
      var convenientTime = 0
      var convenientTimeName = ''
      var receiveChannel = 0
      var receiveChannelName = ''
      var creditLimit = 0
      if (this.companyFlag === 'Y') {
        prefix = this.companyPrefix
        firstName = this.companyContactFirstName
        lastName = this.companyContactLastName
        this.telephone = ''
        this.idCard = ''
        provinceId = this.companyProvinceId
        provinceName = this.companyProvinceName
        districtId = this.companyDistrictId
        districtName = this.companyDistrictName
        email = this.companyContactEmail
        convenientTime = this.companyConvenientTime
        convenientTimeName = this.companyConvenientTimeName
        receiveChannel = this.companyReceiveChannel
        receiveChannelName = this.companyReceiveChannelName
        creditLimit = this.companyCreditLimit
      } else {
        prefix = this.prefix
        firstName = this.firstName
        lastName = this.lastName
        provinceId = this.provinceId
        provinceName = this.provinceName
        districtId = this.districtId
        districtName = this.districtName
        email = this.myEmail
        convenientTime = this.convenientTime
        convenientTimeName = this.convenientTimeName
        receiveChannel = this.receiveChannel
        receiveChannelName = this.receiveChannelName
        creditLimit = this.creditLimit
      }
      await this.axios.post(process.env.VUE_APP_API + '/register/addRegister' + process.env.VUE_APP_DATABASE_TYPE, {
        productSkey: this.productId,
        productName: this.productName,
        prefix: prefix,
        firstName: firstName,
        lastName: lastName,
        telephone: this.telephone,
        provinceId: provinceId,
        provinceName: provinceName,
        districtId: districtId,
        districtName: districtName,
        consentFlag: this.consentFlag,
        email: email,
        idCard: this.idCard,
        companyName: this.companyName,
        companyId: this.companyId,
        companyContactPhone: this.companyContactPhone,
        companyFlag: this.companyFlag,
        guaranteeSkey: this.guaranteeSkey,
        guaranteeName: this.guaranteeName,
        deedNo: this.deedNo,
        guaranteeProvinceId: this.guaranteeProvinceId,
        guaranteeProvinceName: this.guaranteeProvinceName,
        convenientTime: convenientTime,
        convenientTimeName: convenientTimeName,
        receiveChannel: receiveChannel,
        receiveChannelName: receiveChannelName,
        credit_limit: creditLimit,
        user_id: this.userId
      })
        .then(async response => {
          if (response.data.isSuccess) {
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            this.resetData()
            this.dialog.dialogPrint = false
            this.registerSkey = response.data.data.register_skey
            // this.uploadFile()
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async uploadFile () {
      for (let i = 0; i < this.uploadList.length; i++) {
        let formData = new FormData()
        formData.append('register_skey', this.registerSkey)
        formData.append('upload_skey', this.uploadList[i].skey)
        formData.append('file', this.uploadList[i].picture_base64)
        await this.axios.post(process.env.VUE_APP_API + '/register/uploadPicture' + process.env.VUE_APP_DATABASE_TYPE, formData,
          {
            headers: {'Content-Type': 'multipart/form-data'},
          }
        ).then(async response => {
          if (response.data.isSuccess) {
            this.message = response.data.reasonText
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
      }
    },
    async getProductList (language) {
      await this.axios.post(process.env.VUE_APP_API + '/product/retrieveProductList' + process.env.VUE_APP_DATABASE_TYPE, {
        lang: language
      })
      .then(response => {
        this.productList = response.data.data
        console.log(this.product, 'this.product')
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    async getUploadList () {
      this.axios.post(process.env.VUE_APP_API + '/register/retrieveFrontUpload' + process.env.VUE_APP_DATABASE_TYPE, {
      })
        .then(async response => {
          this.uploadList = response.data.data
        })
        .catch(async e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    genRefCode (length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    genOtp (length) {
      var result = ''
      var characters = '1234567890'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    decreseTime () {
      if (this.timeoutOtp > 0) {
        this.timeoutOtp = parseInt(this.timeoutOtp) - 1
      }
    },
    async verify () {
      if (this.smsTo.length === 0) {
        this.$swal({type: 'error', title: 'Please Enter SMS'})
      } else {
        await this.OtpValidateSMS(this.smsTo, this.refCode, this.otp, this.otpDate)
      }
    },
    async send () {
      // this.addRegister()
      if (this.companyFlag === 'N') {
        this.smsTo = this.telephone
      } else {
        this.smsTo = this.companyContactPhone
      }
      if (this.smsTo.length === 0) {
        this.$swal({type: 'error', title: 'Please Enter SMS'})
      } else {
        await this.OtpSendSMS(this.smsTo)
        this.timeoutOtp = 300
      }
    },
    async sms () {
      var postData = '<?xml version="1.0" encoding="TIS-620"?><message><sms type="mt"><service-id>2324091101</service-id><destination><address><number type="international">66868905463</number></address></destination><source><address><number type="abbreviated">40002170</number><originate type="international">66942135525</originate><sender>MEETEE</sender></address></source><ud type="text" encoding="default">SMS gdsfRES</ud><scts>2009-05-21T11:05:29+07:00</scts><dro>true</dro></sms></message>  '

      const headers = {
        'Authorization': 'Basic MjMyNDA5MTEwMTpOY01AQjIxXklFMkZ1aTM=',
        'Content-Type': 'text/plain',
        'Content-Length': Buffer.byteLength(postData)
      }
      this.axios.post('http://119.46.177.99:55000', postData, headers)
        .then(response => {
          if (response.data.isSuccess) {
            console.log(response.data)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async OtpSendSMS (smsTo) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/sms/register/sendSMS', {
        phone_number: smsTo
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.refCode = response.data.refCode
            this.otpDate = Date.parse(response.data.otpDate)
            this.incorrectCount = 0
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async OtpValidateSMS (smsTo, refCode, otp, otpDate) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/sms/register/otpValidateSMS', {
        phone_number: smsTo,
        ref_code: refCode,
        otp: otp,
        otp_date: otpDate,
      })
        .then(response => {
          if (response.data.isSuccess) {
            // this.$swal({type: 'success', title: 'OTP ถูกต้อง'})
            this.addRegister()
            this.dialog.dialogOTP = false
          } else {
            this.incorrectCount++
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    validatePhoneNumber (phone) {
      var ret = true
      if (!phone.match(this.regTelephone)) {
        ret = false
      }
      return ret
    },
    validateIdcard (idCard) {
      var ret = true
      var sum = 0
      var i = 0
      if (!this.IsNumeric(idCard)) {
        ret = false
      } else if (idCard.substring(0, 1) === 0) {
        ret = false
      } else if (idCard.length !== 13) {
        ret = false
      } else {
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseFloat(idCard.charAt(i)) * (13 - i)
        }
        if ((11 - sum % 11) % 10 !== parseFloat(idCard.charAt(12))) {
          ret = false
        } else {
          ret = true
        }
      }
      return ret
    },
    IsNumeric (id) {
      return !isNaN(parseFloat(id)) && isFinite(id)
    },
    validateMail (Email) {
      var ret = true
      if (!this.regEmail.test(Email)) {
        ret = false
      }
      return ret
    }
  }
}
</script>
<style scoped>
  .bg-editor {
    /* `!important` is necessary here because Vuetify overrides this */
    background: rgb(255, 255, 255) !important;
  }
  .input__label {
    color: black;
  }
  .padding-left {
    padding-left: 1px;
  }
  .printing {
    max-width: 100%;
    border: solid 1px #ccc;
    text-align: center;
    padding: 1rem;
    margin: 2rem auto;
    padding-bottom: 2rem;
    border-radius: 5px;
    -webkit-print-color-adjust: exact;
  }

  @page {
      size: A4;
      margin: 0;
  }
  /* @media print {
    table {
      max-height: 100% !important;
      overflow: hidden !important;
      page-break-after: always;
    }
    p {
        page-break-before: always;
    }
  } */

  @media print {
  html, body {
    height:100%; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
    page-break-after: always;
  }
  body * {
  visibility:hidden;
  }
  img { width:100%; }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:1000;
  }
  }
  @page {
      size: A4;
      margin: 0;      
  }
  .custom-bg {
    background: rgb(242, 245, 247) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
</style>